import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userData: {},
  isLoggedIn: false,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    checkLogin: (state) => {
      state.isLoggedIn = !state.isLoggedIn;
    },
    updateUserData: (state, action) => {
      state.userData = { ...state.userData, ...action.payload };
    },
  },
});

export const { checkLogin, updateUserData } = userSlice.actions;
export default userSlice.reducer;
