import { Link, useSearchParams } from "react-router-dom";
import "../../assets/styles/components/footer/Footer.css";
import contacts from "../../constants/contacts";
import { useEffect, useState } from "react";
import outplayed from "../../assets/images/footer/outplayed.svg";

export default function Footer() {
  const [contactsCollectionDesktop, setcontactsCollectionDesktop] = useState(
    []
  );
  const [contactsCollectionMobile, setcontactsCollectionMobile] = useState([]);

  useEffect(() => {
    setcontactsCollectionDesktop((prevcontactsCollectionDesktop) => {
      return contacts.map((contact) => {
        return (
          <a href={contact.url} target="_blank">
            <img src={contact.footerIcon} alt={`${contact.name} Icon`} />
          </a>
        );
      });
    });

    setcontactsCollectionMobile((prevcontactsCollectionMobile) => {
      return contacts.map((contact) => {
        return (
          <a href={contact.url} target="_blank">
            <img
              src={contact.footerIcon}
              alt={`${contact.name} Icon`}
              width="12"
            />
            <span>{contact.name}</span>
          </a>
        );
      });
    });
  }, []);

  return (
    <footer id="footer_m">
      <section className="footer_mobile">
        <section>
          <img src={outplayed} alt="outplayed" />
        </section>
        <section className="features_mobile-sitemap">
          <a href="#features-section">Services</a>
          <a href="#games-section">Games</a>
          <a href="#events-section">Events</a>
          <a href="#join-us-section">Beta Registration</a>
          <Link to="/privacy-policy">Privacy Policy</Link>
          <Link to="/delete-page-one" style={{ color: "#e20d0d" }}>
            Delete Your Account
          </Link>
        </section>
        <section className="features_mobile-contacts">
          {contactsCollectionMobile}
        </section>
        <section
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "2rem",
            color: "#A0A0A0",
          }}
        >
          <span
            style={{
              textAlign: "center",
              color: "#A0A0A0",
              marginBottom: "1rem",
              fontSize: "1.5rem",
            }}
          >
            Outplayed.in was created under Riot Games' "Legal Jibber Jabber"
            policy using assets owned by Riot Games.  Riot Games does not
            endorse or sponsor this project.
          </span>
          <span>
            Coded with 💖 by{" "}
            <a
              href="https://www.linkedin.com/in/shaswata-gogoi/"
              style={{ color: "rgba(89, 117, 195, 1)" }}
            >
              Shaswata Gogoi
            </a>
          </span>
          <span>2024 &copy; Outplayed</span>
        </section>
      </section>

      <section className="footer_desktop">
        <section className="footer_desktop__sitemap">
          <section className="footer_desktop__contact">
            <p>Contact Us</p>
            <a href="mailto:info.outplayed.in@gmail.com">
              info.outplayed.in@gmail.com
            </a>
            <a href="tel:+91 8638163867">+91 8638163867</a>
            <span>{contactsCollectionDesktop}</span>
          </section>
          <section className="footer_desktop__services">
            <p>Services</p>
            <a href="#features-section">Tournament</a>
            <a href="#features-section">Franchise</a>
            <a href="#features-section">Game Profile</a>
            <a href="#features-section">Moderator</a>
            <a href="#features-section">eSports Career</a>
            <a href="#features-section">Events</a>
          </section>
          <section className="footer_desktop__games">
            <p>Games</p>
            <a href="#games-section">Valorant</a>
            <a href="#games-section">BGMI</a>
            <a href="#games-section">MLBB</a>
            <a href="#games-section">Counter Strike</a>
            <a href="#games-section">Freefire</a>
          </section>
          <section className="footer_desktop__get-started">
            <p>Get Started</p>
            <a href="#events-section">Upcoming Event</a>
            <a href="#join-us-section">Beta Testing</a>
            <Link to="/privacy-policy">Privacy Policy</Link>
            <Link to="/delete-page-one" style={{ color: "#E20D0D" }}>
              Delete Your Account
            </Link>
          </section>
        </section>
        <section className="footer_desktop__copyright-notice">
          <section
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
              gap: "0rem",
            }}
          >
            <span>
              Coded with 💖 by{" "}
              <a
                href="https://www.linkedin.com/in/shaswata-gogoi/"
                style={{ color: "rgba(89, 117, 195, 1)" }}
              >
                Shaswata Gogoi
              </a>
            </span>
            <span>2024 &copy; Outplayed</span>
          </section>
          <section>
            <span>
              Outplayed.in was created under Riot Games' "Legal Jibber Jabber"
              policy using assets owned by Riot Games.
            </span>
            <span>Riot Games does not endorse or sponsor this project.</span>
          </section>
        </section>
      </section>
    </footer>
  );
}
