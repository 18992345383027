import { toast } from "react-toastify";
import { globalAxiosCall } from "../services/axios";

export const ProfilePictureDownload = async (uuid) => {
  try {
    const result = await globalAxiosCall({
      baseURL: window._env_.REACT_APP_BASE_STORAGE_LINK,
      url: "/storage/download/user",
      method: "POST",
      token: true,
      data: { uuid },
    });
    return await result?.data?.downloadUrl;
  } catch (error) {
    toast.error("Error in downloading profile picture", {
      theme: "dark",
      autoClose: 1300,
      style: { fontSize: "1rem" },
    });
  }
};
