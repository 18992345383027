import React, { useEffect, useState } from "react";
import "../assets/styles/Login.css";
import "../assets/styles/components/buttons/DeleteAccountButton.css";
import { useNavigate } from "react-router-dom";
import { DeleteAccount } from "../functionality/Delete.functionality";
import { persistor } from "../store/store";
import { deleteToken } from "../utils/tokenfunction";
import { toast } from "react-toastify";
import { ThreeDot } from "react-loading-indicators";

export default function DeletePageTwo() {
  const [deleteWord, setDeleteWord] = useState(null);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handleDelete = async () => {
    try {
      setLoading(true);
      if (deleteWord === "DELETE") {
        const data = await DeleteAccount();

        toast.success("Delete successful!", { theme: "dark", autoClose: 1300, style: {fontSize: "1rem"}});
        
        persistor.purge();
        deleteToken("AccessToken");
        deleteToken("RefreshToken");
        window.location.href = "/";
      }
    } catch (error) {
      toast.error(error.details.message, { theme: "dark", autoClose: 1300, style: {fontSize: "1.5rem"}});
    }finally {
      setLoading(false)
    }
  };
  return (
    <div className="login-container">
      <div className="delete-content-container">
        <div className="login-header">
          <img
            src="/assets/images/features/OutplayedLogo.svg"
            className="login-header-image"
          />
          <p className="login-header-text">Outplayed.in</p>
        </div>
        <div className="login-body">
          <div className="login-body-header">
            <p className="login-body-header-para1">Delete Account</p>
            <p className="login-body-header-para2">
              To confirm deletion, type 'DELETE' below:
            </p>
          </div>
          <div className="login-body-input-section">
            <div className="login-body-input-section-body">
              <input
                type="text"
                placeholder={`Write 'DELETE' here`}
                value={deleteWord}
                onChange={(e) => {
                  const input = e.target.value;
                  setDeleteWord(input);
                }}
              />
            </div>
          </div>
          <div>
            <button
              className="delete-account-button"
              onClick={() => handleDelete()}
              style={{ width: "100%" }}
              disabled={loading}
            >
               {loading ? (
                <ThreeDot color="rgba(54, 59, 119, 0.62)" size="small" />
              ) : (
                `Delete Account`
              )}
            </button>
          </div>
          <p className="login-body-footer-text">
            By clicking the ‘Delete Account’ button, you are providing consent
            to permanently delete your account and agree to the consequences
            outlined above.
          </p>
        </div>
      </div>
    </div>
  );
}
