import React from "react";
import { getToken } from "./tokenfunction";
import { Navigate } from "react-router-dom";

export const SecureRoute = ({ children }) => {
  const isAuthenticated = !!getToken("AccessToken");
  return isAuthenticated ? children : <Navigate to="/" />;
};

export const SecureRegisterRoute = ({ children }) => {
  const isAuthenticated = !!getToken("RegisterToken");
  return isAuthenticated ? children : <Navigate to="/login-register" />;
};
