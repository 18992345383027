import axios from "axios";
import { useState } from "react"
import '../../../assets/styles/components/formPopups/FormPopup.css';
import FormPopup from "../../Popup/FormPopup";
import WarningIcon from '../../../assets/images/errorPopup/warningIcon.svg';
import CheckedIcon from '../../../assets/images/errorPopup/checkedIcon.png';

const baseUrl = window._env_.REACT_APP_BASE_URL;
const gameRoute = window._env_.REACT_APP_GAME_ROUTE;

export default function GamesForm() {

    const [gameTitle, setGameTitle] = useState({
        Suggested_Games: ""
    });
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const [popupReaction, setPopupReaction] = useState("");
    const [popupMessage, setPopupMessage] = useState('')

    const handleChange = (event) => {
        const { name, value } = event.target;
        setGameTitle(prevGameTitle => {
            return {
                Suggested_Games: value
            }
        });
    }

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (isButtonDisabled) return;
        setIsButtonDisabled(true);

        if (gameTitle.Suggested_Games.trim() === "") {
            setGameTitle({
                Suggested_Games: ""
            })
            setShowPopup(true);
            setPopupReaction("Oops!")
            setPopupMessage("Please enter something");
            setTimeout(() => {
                setIsButtonDisabled(false);
            }, 1000);
            setTimeout(() => {
                setShowPopup(false);
                setPopupMessage("");
                setPopupReaction("");
            }, 3000);
            return;
        }

        try {

            const response = await axios.post(window._env_.REACT_APP_BETA_URL, gameTitle);
            setPopupMessage('Form submitted successfully!');
            setShowPopup(true);
            setGameTitle({
                Suggested_Games: ""
            })
            console.log('Form submitted successfully');
        } catch (error) {
            setPopupMessage('Error submitting the form. Please try again.');
            setShowPopup(true);
        } finally {
            setTimeout(() => {
                setIsButtonDisabled(false);
            }, 1000);
            setTimeout(() => {
                setShowPopup(false);
            }, 3000);
        }
    }

    return (
        <>
            <form className="games-form">
                <h1>You want more? Here’s More. </h1>
                <div>
                    <input style={{
                        color: "grey",
                    }} placeholder="Suggest Game" name='game-title' value={gameTitle.Suggested_Games} onChange={handleChange}/>
                    <button onClick={handleSubmit}>
                        {isButtonDisabled ? 'Please wait...' : 'Send'}
                    </button>
                </div>
            </form>
            {
                showPopup 
                && 
                (
                    <FormPopup icon={popupReaction === "Oops!" ? WarningIcon : CheckedIcon} message1={popupReaction === "Oops!" ? "Oops" : "Success"} message2={popupMessage} handleClose={setShowPopup} />
                )
            }
        </>
    )
}