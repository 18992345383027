import React, { useEffect } from "react";
import Accordion from "../components/Accordion/Accordion";
import "../assets/styles/DeletePage.css";
import "../assets/styles/components/buttons/LoginRegisterButton.css";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { persistor } from "../store/store";

export default function () {
  const navigate = useNavigate();
  const {isLoggedIn} = useSelector((state) => state.user);
  const handleDeleteNavigation = () => {
    if (isLoggedIn) navigate("/delete-page-two");
    else navigate("/login-register");
  };

  return (
    <div className="delete-container">
      <div className="delete-header">
        <img src="/assets/images/features/OutplayedLogo.svg" />
        <p>Delete My Account</p>
      </div>
      <div className="requirements-for-deletion">
        <h5>Requirements for Deletion</h5>
        <p>Before proceeding, ensure that:</p>
        <div className="requirements-for-deletion-point">
          <div>
            <img src="/assets/images/features/BulletPoints.svg" />
            <p>You do not own any franchises on the platform.</p>
          </div>
          <div>
            <img src="/assets/images/features/BulletPoints.svg" />
            <p>You are not part of any roster.</p>
          </div>
          <div>
            <img src="/assets/images/features/BulletPoints.svg" />
            <p>
              You are not participating in any upcoming or ongoing tournaments.
            </p>
          </div>
        </div>
      </div>
      <div className="consequences-of-deletion">
        <h5>Consequences of Deletion</h5>
        <p>Please note the following:</p>
        <div className="consequences-of-deletion-point">
          <div>
            <img src="/assets/images/features/BulletPoints.svg" />
            <p>All your existing data will be permanently erased.</p>
          </div>
          <div>
            <img src="/assets/images/features/BulletPoints.svg" />
            <p>
              This process is irreversible. Once completed, your account and
              related data cannot be restored.
            </p>
          </div>
        </div>
      </div>
      <div>
        <div className="handle-account-deletion">
          <h5>How We Handle Account Deletion</h5>
          <p>
            Upon deleting your account, the following actions will be performed:
          </p>
        </div>
        <div
          style={{
            marginTop: "20px",
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            paddingLeft: "10rem",
          }}
        >
          <Accordion
            title={"Anonymization of Personal Information"}
            id={"AccordionOne"}
            childId={"CollapseOne"}
          >
            <div className="accordion-item-content">
              <img src="/assets/images/features/BulletPoints.svg" />
              <p>
                We will remove any personal identifiers associated with your
                account, including Mobile number, Email ID, Name, Username, UPI
                ID, Gender.
              </p>
            </div>
          </Accordion>
          <Accordion
            title={"Profile Removal"}
            id={"AccordionTwo"}
            childId={"CollapseTwo"}
          >
            <div className="accordion-item-body">
              <div className="accordion-item-content">
                <img src="/assets/images/features/BulletPoints.svg" />
                <p>Your profile will no longer be visible on our platform.</p>
              </div>
              <div className="accordion-item-content">
                <img src="/assets/images/features/BulletPoints.svg" />
                <p>Most of your data will be permanently deleted.</p>
              </div>
            </div>
          </Accordion>
          <Accordion
            title={"Retention of Financial Records"}
            id={"AccordionThree"}
            childId={"CollapseThree"}
          >
            <div className="accordion-item-content">
              <img src="/assets/images/features/BulletPoints.svg" />
              <p>
                We will retain records of any financial transactions made for
                legal and business compliance purposes.
              </p>
            </div>
          </Accordion>
          <Accordion
            title={"Retention of Platform-Generated Data"}
            id={"AccordionFour"}
            childId={"CollapseFour"}
          >
            <div className="accordion-item-content">
              <img src="/assets/images/features/BulletPoints.svg" />
              <p>
                Data generated by you on the platform will be kept for
                analytics, fault detection, and improving user experience.
              </p>
            </div>
          </Accordion>
          <Accordion
            title={"Option to Rejoin"}
            id={"AccordionFive"}
            childId={"CollapseFive"}
          >
            <div className="accordion-item-content">
              <img src="/assets/images/features/BulletPoints.svg" />
              <p>
                You will have the ability to create a new account using your
                current credentials, should you wish to return to the platform.
              </p>
            </div>
          </Accordion>
        </div>
        <div
          style={{
            paddingLeft: "10rem",
            marginTop: "4rem",
            marginBottom: "4rem",
          }}
        >
          <button
            className="login-register-button proceed-button"
            onClick={() => handleDeleteNavigation()}
          >
            Proceed
          </button>
        </div>
      </div>
    </div>
  );
}
