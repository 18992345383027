import React from "react";
import "../../assets/styles/components/accordion/accordion.css";

export default function Accordion({ title, children, id, childId }) {
  return (
    <div className="accordion" id={id}>
      <div className="accordion-item">
        <h2 className="accordion-header">
          <button
            className="accordion-button"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target={`#${childId}`}
            aria-expanded="true"
            aria-controls={`${childId}`}
          >
            {title}
          </button>
        </h2>
        <div
          id={childId}
          className="accordion-collapse collapse"
          data-bs-parent={`#${id}`}
        >
          <div className="accordion-body">{children}</div>
        </div>
      </div>
    </div>
  );
}
