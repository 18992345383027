import { globalAxiosCall } from "../services/axios";

export const DeleteAccount = async () => {
  try {
    const result = await globalAxiosCall({
      baseURL: window._env_.REACT_APP_BASE_SUPPORT_LINK,
      url: "/support/delete-account",
      method: "DELETE",
      token: true,
    });
    return result;
  } catch (error) {
    throw error;
  }
};
