import { toast } from "react-toastify";
import { globalAxiosCall } from "../services/axios";
import { getToken } from "../utils/tokenfunction";

export const registerUser = async (data) => {
  try {
    const result = await globalAxiosCall({
      baseURL: window._env_.REACT_APP_BASE_USER_LINK,
      url: "/auth/register",
      method: "POST",
      data: data,
      headers: { Authorization: "Bearer " + getToken("RegisterToken") },
    });
    return result?.data;
  } catch (error) {
    toast.error("Error in registering!", {
      theme: "dark",
      autoClose: 1300,
      style: { fontSize: "1rem" },
    });
    throw error;
  }
};
