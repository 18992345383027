import React from "react";
import { privacyPolicyContent } from "../constants/privacyPolicyConfig";
import CorporateVersionPrivacy from "../components/VersionPrivacy/CorporateVersionPrivacy";
import "../assets/styles/PrivacyPolicy.css";

export default function PrivacyPolicy() {
  return (
    <div>
      <div className="privacy-header">
        <img src="/assets/images/features/OutplayedLogo.svg" />
        <h2>Privacy Policy</h2>
      </div>
      <div className="privacy-body">
        <p>
          <strong>
            <i>Last modified: October 24, 2024</i>
          </strong>
        </p>
        <div className="privacy-body-content">
          <p>
            <strong>{privacyPolicyContent.privacyPolicy.intro}</strong>
          </p>
          <p>
            <strong>{privacyPolicyContent.privacyPolicy.scope}</strong>
          </p>
          <p>
            <strong>{privacyPolicyContent.privacyPolicy.userConsent}</strong>
          </p>
          <p>
            <strong>{privacyPolicyContent.privacyPolicy.advisory}</strong>
          </p>
          <p>
            <strong>
              Representation by user: (a){" "}
              {privacyPolicyContent.privacyPolicy.representationsByUser.a} (b){" "}
              {privacyPolicyContent.privacyPolicy.representationsByUser.b} (c){" "}
              {privacyPolicyContent.privacyPolicy.representationsByUser.c} (d){" "}
              {privacyPolicyContent.privacyPolicy.representationsByUser.d} (e){" "}
              {privacyPolicyContent.privacyPolicy.representationsByUser.e}
            </strong>
          </p>

          <p>
            <strong>
              Representation by Outplayed: (a){" "}
              {privacyPolicyContent.privacyPolicy.representationsByOutplayed.a}{" "}
              (b){" "}
              {privacyPolicyContent.privacyPolicy.representationsByOutplayed.b}
            </strong>
          </p>
        </div>
        <div className="privacy-body-button">
          <p>Corporate Version</p>
        </div>
        <div>
          <CorporateVersionPrivacy />
        </div>
        <div className="privacy-copyright">
          <p>2024 &#169; Outplayed.in</p>
        </div>
      </div>
    </div>
  );
}
