import { toast } from "react-toastify";
import { globalAxiosCall, handleGlobalError } from "../services/axios";

export const sendOTP = async (Contact_Number) => {
  try {
    const result = await globalAxiosCall({
      baseURL: window._env_.REACT_APP_BASE_USER_LINK,
      url: "/auth/login",
      method: "POST",
      data: {
        Contact: Contact_Number,
      },
    });
    return result;
  } catch (error) {
    toast.error("Error in generating OTP", {
      theme: "dark",
      autoClose: 1300,
      style: { fontSize: "1rem" },
    });
  }
};

export const verifyOTP = async (Contact_Number, Otp) => {
  try {
    const result = await globalAxiosCall({
      baseURL: window._env_.REACT_APP_BASE_USER_LINK,
      url: "/auth/verify",
      method: "POST",
      data: {
        Contact: Contact_Number,
        OTP: Otp,
      },
    });
    return result;
  } catch (error) {
    toast.error("Error in verifying OTP", {
      theme: "dark",
      autoClose: 1300,
      style: { fontSize: "1rem" },
    });
  }
};
