import moment from "moment";


export const validateUsername = (username) => {
    if (!username.trim()) {
      return "Username is required.";
    }
    if (/\s/.test(username)) {
      return "Username should not contain spaces.";
    }
    return "";
  };
  
  export const validateDob = (dob) => {
    if (!dob.trim()) {
      return "Date of Birth is required.";
    }
    if (!/^\d{2}\/\d{2}\/\d{4}$/.test(dob)) {
      return "Date of Birth must be in DD/MM/YYYY format.";
    }
    const dobDate = moment(dob, "DD/MM/YYYY");
    const currentDate = moment();
    const age = currentDate.diff(dobDate, "years");
    if (age < 13) {
      return "You must be at least 13 years old.";
    }
    return "";
  };
  
  export const validateGender = (gender) => {
    if (!gender) {
      return "Gender is required.";
    }
    return "";
  };
  
  export const validateCountry = (country) => {
    if (!country) {
      return "Country is required.";
    }
    return "";
  };
  
  export const validateRegion = (region) => {
    if (!region) {
      return "Region is required.";
    }
    return "";
  };
  