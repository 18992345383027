import React, { useEffect, useRef, useState } from "react";
import "../assets/styles/Login.css";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { sendOTP, verifyOTP } from "../functionality/Login.functionality";
import { storeToken } from "../utils/tokenfunction";
import { checkLogin, updateUserData } from "../store/slice/userSlice";
import { toast } from "react-toastify";
import { ThreeDot } from "react-loading-indicators";

export default function OtpVerification() {
  const inputRefs = useRef([]);
  const [otpValue, setOtpValue] = useState(null);
  const [counter, setCounter] = useState(60);
  const [loading, setLoading] = useState(false);
  const { userData } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (counter > 0) {
      const timer = setTimeout(() => setCounter(counter - 1), 1000);
      return () => clearTimeout(timer);
    }
  }, [counter]);

  const handleChange = (e, idx) => {
    const value = e.target.value;

    // Allow only numeric input
    if (!/^\d$/.test(value) && value !== "") return;

    // Update the value of the current input
    const currentInput = inputRefs.current[idx];
    if (currentInput) currentInput.value = value;

    if (value && idx < 5) {
      inputRefs.current[idx + 1].focus();
    }
    // Concatenate the values of all inputs and set OTP
    setOtpValue(inputRefs.current.map((input) => input?.value || "").join(""));
  };

  const handleKeyDown = (e, idx) => {
    if (e.key === "Backspace" && idx > 0 && !e.target.value) {
      inputRefs.current[idx - 1].focus();
    }
  };

  const handleVerify = async () => {
    try {
      setLoading(true);
      const data = await verifyOTP(userData?.Contact, otpValue);
      if (data?.data?.userData) {
        dispatch(
          updateUserData({ ...data?.data?.userData, uploadedImage: true })
        );
        storeToken("AccessToken", data?.data?.accessToken);
        storeToken("RefreshToken", data?.data?.userData?.Refresh_Token);
        dispatch(checkLogin());

        toast.success("Logged in successfully!", {
          theme: "dark",
          autoClose: 1300,
          style: { fontSize: "1.5rem" },
        });

        navigate("/");
      } else {
        storeToken("RegisterToken", data?.data?.accessToken);
        navigate("/register");
      }
    } catch (error) {
      toast.error("Error in verifiying OTP", {
        theme: "dark",
        autoClose: 1300,
        style: { fontSize: "1rem" },
      });
    } finally {
      setLoading(false);
    }
  };

  const handleRetry = async () => {
    if (counter === 0) {
      const data = await sendOTP(userData?.Contact);
      toast.success(data?.data?.message, {
        theme: "dark",
        autoClose: 1300,
        style: { fontSize: "1rem" },
      });
      setCounter(60);
    }
  };
  return (
    <div className="login-container">
      <div className="login-content-container">
        <div className="login-header">
          <img
            src="/assets/images/features/OutplayedLogo.svg"
            className="login-header-image"
          />
          <p className="login-header-text">Outplayed.in</p>
        </div>
        <div className="login-body">
          <div className="login-body-header">
            <p className="login-body-header-para1">Verify OTP</p>
            <p className="login-body-header-para2">
              Enter the 6 digit OTP sent to your Mobile Number.
            </p>
          </div>
          <div className="otp-body-input-section">
            <div>
              {Array.from({ length: 6 }).map((_, idx) => (
                <input
                  key={idx}
                  ref={(el) => (inputRefs.current[idx] = el)}
                  maxLength={1}
                  type="text"
                  style={{ textAlign: "center", fontSize: "2rem" }}
                  onChange={(e) => handleChange(e, idx)}
                  onKeyDown={(e) => handleKeyDown(e, idx)}
                  autoFocus={idx === 0} // Autofocus on the first input field
                />
              ))}
            </div>
          </div>
          <div>
            <button
              className="login-register-button"
              style={{ width: "100%" }}
              onClick={handleVerify}
              disabled={loading}
            >
              {loading ? (
                <ThreeDot color="rgba(54, 59, 119, 0.62)" size="small" />
              ) : (
                `Verify`
              )}
            </button>
          </div>
          <div style={{ display: "flex" }}>
            <p className="login-body-footer-text">Didn’t receive OTP? &ensp;</p>
            <p
              className="login-body-footer-text"
              style={{
                textDecoration: counter === 0 && "underline",
                cursor: counter === 0 && "pointer",
              }}
              onClick={() => handleRetry()}
            >
              {counter > 0 ? `Retry in ${counter} sec.` : "Click here"}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
