import React, { useEffect, useState } from "react";
import "../assets/styles/Login.css";
import "../assets/styles/components/buttons/LoginRegisterButton.css";
import { useDispatch } from "react-redux";
import { updateUserData } from "../store/slice/userSlice";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { sendOTP } from "../functionality/Login.functionality";
import { ThreeDot } from "react-loading-indicators";

export default function Login() {
  const [contactNumber, setContactNumber] = useState(null);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogin = async () => {
    if (!contactNumber || contactNumber.length < 10) {
      alert("Please enter correct contact number");
    } else {
      setLoading(true);
      const data = await sendOTP(contactNumber);
      console.log(data);
      toast.success(data?.data?.message, {
        theme: "dark",
        autoClose: 1300,
        style: { fontSize: "1rem" },
      });
      setLoading(false);
      dispatch(updateUserData({ Contact: contactNumber }));
      navigate("/otp-verification");
    }
  };
  return (
    <div className="login-container">
      <div className="login-content-container">
        <div className="login-header">
          <img
            src="/assets/images/features/OutplayedLogo.svg"
            className="login-header-image"
          />
          <p className="login-header-text">Outplayed.in</p>
        </div>
        <div className="login-body">
          <div className="login-body-header">
            <p className="login-body-header-para1">Login/Register</p>
            <p className="login-body-header-para2">
              Enter your 10 digit mobile Number.
            </p>
          </div>
          <div className="login-body-input-section">
            <p>Mobile Number</p>
            <div className="login-body-input-section-body">
              <img src="/assets/images/features/IndianFlag.svg" />
              <input
                type="number"
                placeholder="Mobile Number"
                value={contactNumber}
                onChange={(e) => {
                  const input = e.target.value;
                  if (input.length <= 10) {
                    setContactNumber(input);
                  }
                }}
              />
            </div>
          </div>
          <div>
            <button
              className="login-register-button"
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              disabled={loading}
              onClick={handleLogin}
            >
              {loading ? (
                <ThreeDot color="rgba(54, 59, 119, 0.62)" size="small" />
              ) : (
                `Sign in`
              )}
            </button>
          </div>
          <p className="login-body-footer-text">
            We only save your mobile number to send you OTP.
          </p>
        </div>
      </div>
    </div>
  );
}
