import React from "react";
import "../../assets/styles/components/modals/DeleteCenteredModal.css";
import "../../assets/styles/components/buttons/DeleteButton.css";
import "../../assets/styles/components/buttons/CancelButton.css";

export default function DeleteCenteredModal({
  className = "modal fade",
  id,
  title,
  body,
  onClick,
}) {
  return (
    <div className={className} id={id} tabindex="-1">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="centerModal-content">
            <div className="centerModal-content-header">
              <img
                src="/assets/images/features/ErrorIcon.svg"
                style={{ height: "40px", aspectRatio: 1 }}
              />
              <h5>{title}</h5>
            </div>
            <p>{body}</p>
            <div className="footer-button">
              <button
                className="delete-button"
                data-bs-toggle="modal"
                data-bs-target="#centerModal"
                onClick={onClick}
              >
                Delete
              </button>
              <button
                className="cancel-button"
                data-bs-toggle="modal"
                data-bs-target="#centerModal"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
