export const storeToken = (tokenName, token) => {
  if (token) {
    localStorage.setItem(tokenName, token); // Save token to localStorage
  }
};

export const getToken = (tokenName) => {
  return localStorage.getItem(tokenName); // Retrieve the token from localStorage
};

export const deleteToken = (tokenName) => {
  localStorage.removeItem(tokenName); // Remove token from localStorage
};
