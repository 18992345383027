import React, { useEffect, useState } from "react";
import "../assets/styles/Login.css";
import "../assets/styles/components/buttons/LoginRegisterButton.css";
import { GenderOptions } from "../constants/gender";
import { State } from "country-state-city";
import { registerUser } from "../functionality/Register.functionality";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { checkLogin, updateUserData } from "../store/slice/userSlice";
import { deleteToken, storeToken } from "../utils/tokenfunction";
import {
  validateCountry,
  validateDob,
  validateGender,
  validateRegion,
  validateUsername,
} from "../utils/validation";
import { toast } from "react-toastify";
import { ThreeDot } from "react-loading-indicators";

export default function Register() {
  const [username, setUserName] = useState("");
  const [regionData, setRegionData] = useState([]);
  const [countryData, setCountryData] = useState([
    {
      label: "India",
      value: "India",
    },
  ]);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedRegion, setSelectedRegion] = useState("");
  const [selectedGender, setSelectedGender] = useState("");
  const [dob, setDob] = useState("");
  const [loading, setLoading] = useState(false);

  const [errors, setErrors] = useState({
    username: "",
    dob: "",
    gender: "",
    country: "",
    region: "",
  });

  const dispatch = useDispatch();
  const { userData } = useSelector((state) => state.user);

  useEffect(() => {
    let states = State.getStatesOfCountry("IN").map((state) => ({
      label: state.name,
      value: state.name,
    }));
    setRegionData(states);
  }, []);

  const handleRegister = async () => {
    const usernameError = validateUsername(username);
    const dobError = validateDob(dob);
    const genderError = validateGender(selectedGender);
    const countryError = validateCountry(selectedCountry);
    const regionError = validateRegion(selectedRegion);

    if (
      usernameError ||
      dobError ||
      genderError ||
      countryError ||
      regionError
    ) {
      setErrors({
        username: usernameError,
        dob: dobError,
        gender: genderError,
        country: countryError,
        region: regionError,
      });
      return;
    }
    try {
      setLoading(true);
      let body = {
        Username: username,
        Country: selectedCountry,
        Account_Status: "Active",
        Gender: selectedGender,
        DOB: moment(dob, "DD/MM/YYYY").format("YYYY/MM/DD"),
        Region: selectedRegion,
        ISD_Code: "91",
      };
      const data = await registerUser(body);
      deleteToken("RegisterToken");
      dispatch(checkLogin());
      dispatch(
        updateUserData({ ...body, UUID: data?.UUID, uploadedImage: false })
      );
      storeToken("AccessToken", data?.accessToken);
      storeToken("RefreshToken", data?.refreshToken);
      window.location.href = "/";
    } catch (error) {
      toast.error("Error in registering", {
        theme: "dark",
        autoClose: 1300,
        style: { fontSize: "1rem" },
      });
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className="login-container">
      <div className="register-content-container">
        <div className="login-header">
          <img
            src="/assets/images/features/OutplayedLogo.svg"
            className="login-header-image"
          />
          <p className="login-header-text">Outplayed.in</p>
        </div>
        <div className="register-body">
          <div className="login-body-header">
            <p className="login-body-header-para1">Signup</p>
            <p className="login-body-header-para2">
              Enter your details to proceed.
            </p>
          </div>
          <div className="register-body-input-section">
            <p>Username</p>
            <div className="register-body-input-section-body">
              <div>
                <input
                  type="text"
                  placeholder="Username"
                  value={username}
                  onChange={(e) => {
                    setUserName(e.target.value);
                    setErrors({
                      ...errors,
                      username: validateUsername(e.target.value),
                    });
                  }}
                />
                {errors.username && (
                  <span className="error-text">{errors.username}</span>
                )}
              </div>
            </div>
          </div>
          <div className="register-body-input-section">
            <p>DOB</p>
            <div className="register-body-input-section-body">
              <div>
                <input
                  type="text"
                  placeholder="Date of Birth"
                  value={dob}
                  onChange={(e) => {
                    setDob(e.target.value);
                    setErrors({ ...errors, dob: validateDob(e.target.value) });
                  }}
                />
                {errors.dob && <span className="error-text">{errors.dob}</span>}
              </div>
            </div>
          </div>
          <div className="register-body-input-section">
            <p>Gender</p>
            <div>
              <div>
                <button
                  className="dropdown-toggle"
                  type="button"
                  data-bs-toggle="dropdown"
                  style={{ color: selectedGender ? "white" : "grey" }}
                >
                  {selectedGender || "Gender"}
                </button>
                <ul
                  className="dropdown-menu"
                  style={{
                    width: "24.4%",
                  }}
                >
                  {Object.keys(GenderOptions).map((key) => {
                    return (
                      <li
                        className="dropdown-item list-style"
                        onClick={() => {
                          setSelectedGender(GenderOptions[key]);
                          setErrors({
                            ...errors,
                            gender: validateGender(GenderOptions[key]),
                          });
                        }}
                      >
                        {GenderOptions[key]}
                      </li>
                    );
                  })}
                </ul>
                {errors.gender && (
                  <span className="error-text">{errors.gender}</span>
                )}
              </div>
            </div>
          </div>
          <div className="register-body-input-section">
            <p>Mobile Number</p>
            <div className="register-body-input-section-body">
              <img src="/assets/images/features/IndianFlag.svg" />
              <div>
                <input
                  type="number"
                  placeholder="Mobile Number"
                  value={userData?.Contact}
                />
              </div>
            </div>
          </div>
          <div style={{ display: "flex", gap: 10 }}>
            <div className="register-body-input-section">
              <p>Country</p>
              <div className="dropup">
                <div>
                  <button
                    className="dropdown-toggle country-state-list"
                    type="button"
                    data-bs-toggle="dropdown"
                    style={{
                      overflow: "hidden",
                      color: selectedCountry ? "white" : "grey",
                    }}
                  >
                    <span>{selectedCountry || "Country"}</span>
                  </button>
                  <ul className="dropdown-menu" style={{ width: "100%" }}>
                    {countryData.map((key) => {
                      return (
                        <li
                          className="dropdown-item list-style"
                          onClick={() => {
                            setSelectedCountry(key?.value);
                            setErrors({
                              ...errors,
                              country: validateCountry(key?.value),
                            });
                          }}
                        >
                          {key?.label}
                        </li>
                      );
                    })}
                  </ul>
                  {errors.country && (
                    <span className="error-text">{errors.country}</span>
                  )}
                </div>
              </div>
            </div>
            <div className="register-body-input-section">
              <p>Region</p>
              <div className="dropup">
                <div>
                  <button
                    className="dropdown-toggle country-state-list"
                    type="button"
                    data-bs-toggle="dropdown"
                    style={{
                      color: selectedRegion ? "white" : "grey",
                    }}
                  >
                    <span>{selectedRegion || "Region"}</span>
                  </button>
                  <ul
                    className="dropdown-menu "
                    style={{
                      position: "absolute",
                      bottom: "100%",
                      maxHeight: "500px",
                      overflowY: "scroll",
                      backgroundColor: "#fff",
                      border: "1px solid #ccc",
                    }}
                  >
                    {regionData?.map((key) => {
                      return (
                        <li
                          className="dropdown-item "
                          onClick={() => {
                            setSelectedRegion(key?.value);
                            setErrors({
                              ...errors,
                              region: validateRegion(key?.value),
                            });
                          }}
                        >
                          {key?.label}
                        </li>
                      );
                    })}
                  </ul>
                  {errors.region && (
                    <span className="error-text">{errors.region}</span>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div>
            <button
              className="login-register-button"
              style={{ width: "100%", marginBottom: "2rem", marginTop: "2rem" }}
              onClick={handleRegister}
              disabled={loading}
            >
              {loading ? (
                <ThreeDot color="rgba(54, 59, 119, 0.62)" size="small" />
              ) : (
                `Register`
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
